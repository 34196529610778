import * as React from "react";
import type { HeadFC } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import ImageHero from "../components/shared/ImageHero";
import ImageFeatureSection from "../components/shared/sections/ImageFeatureSection";
import { StaticImage } from "gatsby-plugin-image";

const pageDescription =
  "Providing insurance solutions for personal and commercial markets. Comprehensive intermediary services are also offered to our clients for both long and short term insurance.";
const pagesBreadcrumb = [
  {
    name: "Insurances",
    to: "/insurances",
  },
];

const InsurancesPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Nora Insure for peace of mind"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div>
            <ImageFeatureSection
              alignImage="left"
              title="Long-term Insurance"
              description="We provide tailor-made solutions for your specific needs, from Group Funeral Schemes, Personal Funeral Benefits, Pension Funds Benefits to Group Life Cover."
            >
              <StaticImage
                quality={100}
                className="w-full"
                src="../images/insurances-1.jpg"
                alt="Two men in suits with one holding up a paper on a desk"
              />
            </ImageFeatureSection>
          </div>
          <div className="mt-24">
            <ImageFeatureSection
              alignImage="right"
              title="Short-term Insurance"
              description="We offer a wide range of tailor-made products to protect your assets, household content and valuable possessions from loss, theft or damage. Products are competitively priced and aligned to your risk profile."
            >
              <StaticImage
                quality={100}
                className="w-full"
                src="../images/insurances-2.jpg"
                alt="A pen and an insurance checkbox with a red tick"
              />
            </ImageFeatureSection>
          </div>
          <div className="mt-24">
            <ImageFeatureSection
              alignImage="left"
              title="Funeral Services"
              description="Nora Funeral Services offers a range of affordable funeral packages to ensure a dignified farewell for your loved ones. We assist the family with the necessary funeral arrangements and paperwork, repatriation of the deceased as well cremation and chapel services."
            >
              <StaticImage
                quality={100}
                className="w-full"
                src="../images/insurances-3.jpg"
                alt="Two men in suits with one holding up a paper on a desk"
              />
            </ImageFeatureSection>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default InsurancesPage;

export const Head: HeadFC = () => (
  <CustomHead title="Insurances" description={pageDescription} />
);
